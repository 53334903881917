import DatePicker, { DatePickerProps } from 'react-datepicker'
import { DateNavigation } from './DateNavigation'
import { useCallback, useContext, useState } from 'react'
import { VariableServicesContext } from '../../services'
import Utils from '../../services/utils'

export type DateFieldProps = Pick<
    DatePickerProps,
    | 'id'
    | 'inline'
    | 'placeholderText'
    | 'toggleCalendarOnIconClick'
    | 'className'
    | 'disabled'
    | 'selected'
    | 'onBlur'
    | 'onKeyDown'
    | 'children'
    | 'popperPlacement'
    | 'selectsStart'
    | 'selectsEnd'
    | 'startDate'
    | 'endDate'
    | 'minDate'
    | 'maxDate'
> & {
    endYear?: number
    onChange?: (date: Date | null, e?: any) => void
}

let timeout: NodeJS.Timeout | undefined = undefined

export const DateField = (props: DateFieldProps) => {
    const { userService } = useContext(VariableServicesContext)
    const [year, setYear] = useState<number>()
    const [month, setMonth] = useState<number>()

    const onChange = useCallback(
        (d: Date | Date[] | [Date | null, Date | null] | null, e?: any) => {
            if (timeout) clearTimeout(timeout)
            timeout = setTimeout(() => {
                if (Array.isArray(d)) d = d[0]
                props.onChange?.(d, e)
            }, 300)
        },
        [props.onChange],
    )

    return (
        <DatePicker
            dateFormat={Utils.getLocaleDateString()}
            calendarStartDay={userService.getFirstDayOfWeek()}
            renderCustomHeader={(p) => <DateNavigation {...p} endYear={props.endYear} />}
            popperPlacement='bottom'
            popperClassName='position-fixed'
            showPopperArrow={false}
            className='variable-form-control bg-light w-100'
            onMonthChange={(d) => {
                if (!props.selected) return
                setMonth(d.getMonth())
                const newDate = new Date(props.selected)
                newDate.setMonth(d.getMonth())
                newDate.setFullYear(year !== undefined ? year : d.getFullYear())
                onChange(newDate)
            }}
            onYearChange={(d) => {
                if (!props.selected) return
                setYear(d.getFullYear())
                const newDate = new Date(props.selected)
                newDate.setMonth(month !== undefined ? month : d.getMonth())
                newDate.setFullYear(d.getFullYear())
                onChange(newDate)
            }}
            onChange={(d: Date | Date[] | [Date | null, Date | null] | null, e) => {
                if (Array.isArray(d)) d = d[0]
                props.onChange?.(d, e)
            }}
            {...props}
        >
            {props.children}
        </DatePicker>
    )
}
