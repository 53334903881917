import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import Button from './Button'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import { YearSelector } from './YearSelector'
import { MonthSelector } from './MonthSelector'
import Utils from '../../services/utils'

export const DateNavigation = (params: ReactDatePickerCustomHeaderProps & { endYear?: number }) => (
    <div className='d-flex align-items-center justify-content-center gap-1 px-2'>
        <Button
            className='btn btn-xs bg-light-hover me-auto'
            onClick={params.decreaseMonth}
            disabled={params.prevMonthButtonDisabled}
        >
            <CaretLeft size={Utils.verySmallIconSize} />
        </Button>
        <MonthSelector
            placement='bottom-start'
            option={params.date.getMonth()}
            onChange={params.changeMonth}
            style={{ width: '6rem' }}
            className='variable-selector fw-bold text-nowrap'
            tooltipClassName='fs-base'
            itemExtraClassName='small'
        />
        <YearSelector
            placement='bottom-start'
            option={params.date.getFullYear()}
            onChange={params.changeYear}
            startYear={1970}
            endYear={params.endYear}
            style={{ width: '4rem' }}
            className='variable-selector fw-bold'
            tooltipClassName='fs-base'
            itemExtraClassName='small'
        />
        <Button
            className='btn btn-xs bg-light-hover ms-auto'
            onClick={params.increaseMonth}
            disabled={params.nextMonthButtonDisabled}
        >
            <CaretRight size={Utils.verySmallIconSize} />
        </Button>
    </div>
)
